<template>
  <div>
    <!-- 顶部搜索 -->
    <div class="searchBar">
      <div class="container inner">
        <div class="search">
          <el-select
            class="leftSelect"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="searchValue"
            placeholder="交易hash/证书编号"
          ></el-input>
        </div>
        <a-button
          type="primary"
          size="large"
          @click="searchCertificate"
        > 搜索 </a-button>
      </div>
    </div>

    <div
      v-if="searchResultVisible"
      class="container resultView"
    >
      <!-- 证书搜索 -->
      <template v-if="resultType==1">
        <div class="commonTitle"> 搜索结果 </div>
        <div @click="resultType=2">查看上链信息</div>
        <div class="imgWrap">
          <el-image
            :src="certificateImg"
            :preview-src-list="[certificateImg]"
          >
          </el-image>
        </div>
      </template>

      <!-- hash搜索-->
      <template v-if="resultType==2">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>中国知链</el-breadcrumb-item>
          <el-breadcrumb-item>区块链详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="commonWhiteBg HashResult">
          <div class="commonTitle"> 区块链详情 </div>
          <table class="detailTable">
            <tr>
              <td class="label"> 公示hash </td>
              <td class="value"> {{transactionInfo.transactionData.hash}} </td>
            </tr>
            <tr>
              <td class="label"> 公示时间 </td>
              <td class="value"> {{transactionInfo.transactionData.timeText}} </td>
            </tr>
            <tr>
              <td class="label"> 交易类型 </td>
              <td class="value"> {{transactionInfo.transactionData.txType}} </td>
            </tr>
            <tr>
              <td class="label"> 区块高度 </td>
              <td class="value"> {{transactionInfo.blockNumber}} </td>
            </tr>
            <tr>
              <td class="label"> 区块hash </td>
              <td class="value"> {{transactionInfo.blockData.blockHash}} </td>
            </tr>
            <tr>
              <td class="label"> Nonce </td>
              <td class="value"> {{transactionInfo.transactionData.nonce}} </td>
            </tr>
            <tr>
              <td class="label"> 发起账户 </td>
              <td class="value"> {{transactionInfo.transactionData.from}} </td>
            </tr>
            <tr>
              <td class="label"> 目标账户 </td>
              <td class="value"> {{transactionInfo.transactionData.to}} </td>
            </tr>
            <tr>
              <td class="label"> 交易金额 </td>
              <td class="value"> {{transactionInfo.transactionData.value}} </td>
            </tr>
            <tr>
              <td class="label"> 证书 </td>
              <td class="value" @click="resultType=1"> 查看 </td>
            </tr>
            <!-- <tr>
              <td class="label"> 签名 </td>
              <td class="value">
                 <el-popover
                  placement="right"
                    width="400"
                  trigger="click"
                >
                    <el-table
                    width="100%"
                    :data="gridData"
                  >
                    <el-table-column
                      property="code"
                      label="签名"
                    ></el-table-column>
                  </el-table>
                  <a slot="reference">详情</a>
                </el-popover>
              </td>
            </tr>
            <tr>
              <td class="label"> 交易收据 </td>
              <td class="value">
                <el-popover
                  placement="right"
                    width="400"
                  trigger="click"
                >
                  <el-table
                    width="100%"
                    :data="gridData2"
                  >
                    <el-table-column
                      property="result"
                      label="result"
                    ></el-table-column>
                    <el-table-column
                      property="data"
                      label="data"
                    ></el-table-column>
                    <el-table-column
                      property="gasUsed"
                      label="gasUsed"
                    ></el-table-column>
                    <el-table-column
                      property="log"
                      label="log"
                    ></el-table-column>
                  </el-table>
                  <a slot="reference">详情</a>
                </el-popover>
              </td>
            </tr>
            <tr>
              <td class="label"> 交易金额 </td>
              <td class="value"> 0 </td>
            </tr> -->
          </table>
        </div>
      </template>
    </div>
    <div v-else>
      <!-- 数字报表板块 -->
      <div class="reportView">
        <div class="fluid container">
          <a-row>
            <a-col
              :md="24"
              :lg="12"
              :xl="6"
              v-for="(item,index) in list"
              :key="index"
            >
              <div class="block">
                <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-icon'+(index+1)+'.png'">
                <div>
                  <div class="tittle">
                    <span
                      class="dot"
                      :class="{'blueDot':item.color=='blue','orangeDot':item.color=='orange','greenDot':item.color=='green'}"
                    > </span>
                    <span> {{item.tittle}} </span>
                  </div>
                  <h3>
                    <ICountUp
                      :delay="delay"
                      :endVal="item.num"
                    />
                    <span class="dan"> {{item.dan}} </span>
                  </h3>

                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- 地图 -->
      <div class="ipChainMap">
        <div class="container">
          <div class="top">
            <h3> 中国知链节点分布状态 </h3>
            <p class="desc"> 中国知链是一个横跨全球五大洲部署节点，部署地囊括中国上海，新加坡，法兰克福，弗吉尼亚等，跨区域节点分布让记录在原本链上的原创作品认证具有极强的公信力与不可篡改性。 </p>
          </div>
          <div class="map">
            <img
              src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-map.png"
              width="100%"
            >
            <p class="position position1"><span class="shineDot greenShineDot"></span> <span>多伦多</span></p>
            <p class="position position2"><span class="shineDot orangeShineDot"></span> <span>弗吉利亚</span></p>
            <p class="position position3"><span>伦敦</span> <span class="shineDot orangeShineDot"></span></p>
            <p class="position position4"><span class="shineDot greenShineDot"></span> <span>法兰克福</span></p>
            <p class="position position5"><span class="shineDot greenShineDot"></span> <span>莫斯科</span></p>
            <p class="position position6"><span class="shineDot greenShineDot"></span> <span>湖北武汉</span></p>
            <p class="position position7"><span class="shineDot greenShineDot"></span> <span>上海</span></p>
            <p class="position position8"><span class="shineDot greenShineDot"></span> <span>中国香港</span></p>
            <p class="position position9"><span class="shineDot greenShineDot"></span> <span>圣保罗</span></p>
            <ul class="legend">
              <li> <span class="shineDot greenShineDot"> </span> <span>已开放区域</span> </li>
              <li> <span class="shineDot orangeShineDot"> </span> <span>海外合作基础设施</span> </li>
            </ul>
          </div>
        </div>

      </div>

      <div class="grayBg downBlock">
        <div class="container">
          <a-row>
            <a-col class="descCol">
              <div class="desc">
                <div class="bigTittle"> 性能优越的可信数据存证方案 </div>
                <div class="topWords"> 中国知链是一条自主研发的区块链架构，针对个性化的使用场景全面优化后的高性能区块链。 </div>
                <div class="smallTittle">多知名公链存证锚定</div>
                <div class="smallWords">将数据指纹同步存证到多个知名公链，形成一个完整的证据闭环</div>
                <div class="smallTittle">中国知链数据不可更改</div>
                <div class="smallWords">已发布的DNA无法更改，使得区块链数据不可更改</div>
              </div>
            </a-col>
            <a-col class="imgCol">
              <img
                src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-img1.svg"
                width="100%"
              >
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="whiteBg downBlock">
        <div class="container">
          <a-row>
            <a-col
              :lg="24"
              :xl="10"
              class="imgCol"
            >
              <img
                src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-img2.svg"
                width="100%"
              >
            </a-col>
            <a-col
              :lg="24"
              :xl="14"
              class="descCol"
            >
              <div class="desc">
                <div class="bigTittle"> 去中心化可信内容协议 </div>
                <div class="topWords"> 中国知链的目标是成为中国出版行业的司法存证系统，通过开放的去中心化可信内容协议为数字内容生成独一无二的知链DNA，基于去中心化可信内容协议能够让优质原创作品获得更多的收益和更好的传播。 </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { queryChainSummary } from '@/api/ChainService'
import { queryCertificateByHash,queryCertificate } from '@/api/CertificateService'
import ICountUp from 'vue-countup-v2'
export default {
  name: 'IpChain',
  components: {
    ICountUp
  },
  data () {
    return {
      searchResultVisible: false,
      resultType: 2,
      gridData: [{
        code: 'f5869b5adc0e366b5ae65a8d5ec546da42b14acb46b892161a6543d594306e21'
      }],
      gridData2: [{
        result: '正常',
        data: '',
        gasUsed: '26110',
        log: ''
      }],
      certificateImg: 'https://dbk-read.oss-cn-hangzhou.aliyuncs.com/1/1/2021/01/29/read/system/evidence/certificate/img/2/16777236/900-8324242-571389.jpg',
      certificateInfo:null,
      transactionInfo:null,
      options: [{
        value: '-1',
        label: '全部'
      },],
      value: '-1',
      searchValue: this.$route.params.transactionHash||'',
      list: [
        {
          tittle: '最新高度',
          num: 1277390,
          color: 'green'
        },
        {
          tittle: '最新区块时间',
          num: 12,
          dan: '秒前',
          color: 'blue'
        },
        {
          tittle: '区块交易数',
          num: 1211123,
          color: 'orange'
        },
        {
          tittle: '活跃节点数',
          num: 912,
          color: 'orange'
        },
        {
          tittle: '实时TPS',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '未确认交易',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '平均区块时间',
          num: 9,
          dan: '秒',
          color: 'blue'
        },
        {
          tittle: '网络节点',
          num: 14,
          color: 'green'
        },
      ],
      delay: 1000,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let that = this;
      queryChainSummary().then(({ data }) => {
        console.log(data);
        let list = that.list;
        list[0].num = data.latestBlockHeight * 1;
        list[1].num = Math.round(Date.now() / 1000) - Math.round(data.latestBlockTime);
        list[2].num = data.totalTransactionCount * 1;
        list[3].num = data.activePeerCount * 1;
        list[4].num = data.tps * 1;
        list[5].num = data.unconfirmedTransactionCount * 1;
        list[6].num = 1;
        list[7].num = data.totalPeerCount * 1;
        that = list = list;
      })
      if(this.searchValue){
        // this.searchValue=this.$route.params.transactionHash;
        this.searchCertificate(); 
      }
    },
    searchCertificate(){
      let that=this;
        if(this.searchValue.indexOf('-')>-1){
          //根据证书编号查询证书
          queryCertificate(this.searchValue).then(({data})=>{
              console.log(data);
              that.resultType="1";
              that.searchResultVisible=true;
              that.certificateImg=data.certificateInfo.jpgUrl;

               that.certificateInfo=data;
             that.transactionInfo=data.transactionInfo
             
             that.transactionInfo.transactionData.timeText=that.dayjs(that.transactionInfo.transactionData.timestamp).format('YYYY-MM-DD HH:mm:ss');

          });
        }else{
          //根据hash查询证书
           queryCertificateByHash(this.searchValue).then(({data})=>{
             console.log(data);
             that.resultType="2";
             that.certificateImg=data.certificateInfo.jpgUrl;

             that.certificateInfo=data;
             that.transactionInfo=data.transactionInfo
             
             that.transactionInfo.transactionData.timeText=that.dayjs(that.transactionInfo.transactionData.timestamp).format('YYYY-MM-DD HH:mm:ss');
             that.searchResultVisible=true;
          });
        }
    },
  }
}
</script>
<style lang="scss" scoped>
.HashResult {
  padding: 34px 36px;
  .commonTitle {
    font-size: 16px;
  }
  .detailTable {
    width: 100%;
    tr {
      border-bottom: solid 1px #eee;
      td {
        padding: 16px;
        vertical-align: top;
      }
      .label {
        width: 25%;
        color: rgba(0, 18, 22, 0.5);
      }
      .value {
        color: rgba(0, 18, 22, 1);
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}
.searchBar {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-topBanner.png')
    no-repeat 50% 100%;
  background-size: 100% 100%;
  padding: 72px 0;
  /deep/ .el-input.is-active .el-input__inner,
  /deep/ .el-input__inner:focus {
    box-shadow: none;
    border: none;
  }
  .inner {
    display: flex;
    .ant-btn {
      margin-left: 8px;
      height: 48px;
      border-radius: 2px;
      padding: 0 21px;
    }
  }
  .search {
    height: 48px;
    line-height: 48px;
    background: #fff;
    border-radius: 2px;
    flex: 1;
    display: flex;
    /deep/ .el-select .el-input.is-focus .el-input__inner,
    /deep/ .el-input__inner {
      border: none;
    }
    .leftSelect /deep/ .el-input__inner {
      text-align: center;
      color: rgba(0, 18, 22, 0.45);
    }
  }
}

.reportView {
  padding: 72px 0;
  .block {
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 109, 117, 0.06);
    padding: 32px 88px 16px 16px;
    height: 141px;
    position: relative;
    color: #001216;
    img {
      position: absolute;
      right: 0px;
      top: 4px;
      width: 66px;
    }
    h3 {
      margin: 8px 0 0 24px;
      font-size: 24px;
      display: flex;
      white-space: nowrap;
    }
    .dan {
      margin-left: 4px;
    }
    .tittle {
      display: flex;
      align-items: center;
    }
    .dot {
      background: radial-gradient(
        circle,
        rgba(144, 178, 255, 1),
        rgba(238, 243, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-right: 8px;
      display: block;
    }
    .blueDot {
      background: radial-gradient(
        circle,
        rgba(144, 178, 255, 1),
        rgba(238, 243, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
    .greenDot {
      background: radial-gradient(
        circle,
        rgba(42, 239, 239, 1),
        rgba(204, 251, 247, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
    .orangeDot {
      background: radial-gradient(
        circle,
        rgba(255, 165, 103, 1),
        rgba(255, 243, 234, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
  }
}

.ipChainMap {
  background-image: linear-gradient(0deg, #002858 20%, #0069E4 80%);
  color: #fff;
  padding: 72px 0;
  .top {
    text-align: center;
  }
  h3 {
    font-size: 24px;
    color: #fff;
  }
  .desc {
    color: rgba($color: #fff, $alpha: 0.45);
    margin: 16px 0 32px;
  }
  .map {
    position: relative;
    .position {
      position: absolute;
      display: flex;
      align-items: center;
      & > * {
        margin-right: 8px;
      }
    }
    .position1 {
      left: 24%;
      top: 30.59%;
    }
    .position2 {
      left: 22.88%;
      top: 39.76%;
    }
    .position3 {
      left: 41.92%;
      top: 29.22%;
    }
    .position4 {
      left: 50%;
      top: 35.21%;
    }
    .position5 {
      left: 55.87%;
      top: 24.06%;
    }
    .position6 {
      left: 74.62%;
      top: 36.76%;
    }
    .position7 {
      left: 79.71%;
      top: 44.73%;
    }
    .position8 {
      left: 75.58%;
      top: 52.88%;
    }
    .position9 {
      left: 32.21%;
      top: 81.91%;
    }
    @keyframes scale {
      0% {
        transform: scale(1);
      }
      50%,
      75% {
        transform: scale(3);
      }
      78%,
      100% {
        opacity: 0;
      }
    }
    .shineDot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .shineDot:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 0.7;
      animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
      animation-delay: 200ms;
    }
    .greenShineDot {
      background-color: #01e5d4;
    }
    .greenShineDot::before {
      background-color: #01e5d4;
    }
    .orangeShineDot {
      background-color: #ff9875;
    }
    .orangeShineDot::before {
      background-color: #ff9875;
    }
    .legend {
      position: absolute;
      left: 3.85%;
      bottom: 0;
      li {
        margin-top: 16px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.grayBg {
  background: #f9fafa;
}
.whiteBg {
  background: #fff;
}
.downBlock {
  .desc {
    .bigTittle {
      color: rgba(0, 18, 22, 0.85);
      font-size: 24px;
    }
    .topWords {
      color: rgba(1, 43, 53, 0.6);
      margin-top: 24px;
    }
    .smallTittle {
      color: rgba(0, 18, 22, 0.6);
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-top: 32px;
    }
    .smallTittle::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: #007DFF;
      border-radius: 100%;
      margin-right: 8px;
    }
    .smallWords {
      color: rgba(11, 21, 24, 0.6);
      margin-left: 16px;
      margin-top: 8px;
      text-align: left;
    }
  }
  .ant-row {
    display: flex;
    align-items: center;
    padding: 40px 0;
    .imgCol {
      width: 50%;
      img{
        height: 400px;
      }
    }
    .descCol {
      width: 50%;
    }
  }
}
@media (max-width: 1200px) {
  .reportView {
    .block {
      display: flex;
      align-items: center;
    }
  }
}
.resultView {
  margin: 32px auto;
  .imgWrap {
    display: flex;
    justify-content: center;
    /deep/ .el-image {
      width: 80%;
      max-width: 595px;
    }
  }
}

@media (max-width: 992px) {
  .shineDot,
  .shineDot::before {
    width: 4px;
    height: 4px;
  }
  .map {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .HashResult{
    padding:24px 16px;
  }
  .ant-row {
    flex-wrap: wrap;
    .imgCol {
      order: 1;
      flex: 100%;
    }
    .descCol {
      order: 2;
      flex: 100%;
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>