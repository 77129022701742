import request from '@/utils/request'



export function queryCertificate(certificateCode) {
    return request(
        'get',
        '/v1',
       `/evidence/certificate/cnpkp_${certificateCode}`
    );
}

export function queryCertificateByHash(hash) {
    return request(
        'get',
        '/v1',
       `/evidence/certificate/hash/${hash}`
    );
}