import request from '@/utils/request'



export function queryChainSummary() {
    return request(
        'get',
        '/v1',
       `/chn/chain/ethchain/summary`
    );
}
